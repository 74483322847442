<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="6" class="d-inline-flex">
        <h3>{{ $lang.labels[type] }}</h3>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ condition: '', query: '' }), editRow(deconstructedData.length - 1)"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-card :key="i" flat class="pa-2 mb-1" style="position: relative">
            <div class="d-inline-flex" style="position: absolute; top: 8px; right: 8px">
              <v-btn
                icon
                small
                :disabled="!canEdit"
                @click="editRow(i)"
              >
                <v-icon color="info">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <v-row no-gutters align="center" class="pb-1">
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.condition }}</p>
                <p class="pb-0 mb-0">{{ item.condition.length > 150 ? `${item.condition.substring(0, 147)}...` : item.condition }}</p>
              </v-col>
              <v-col cols="12" class="my-1">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.query }}</p>
                <p class="pb-0 mb-0">{{ item.query.length > 150 ? `${item.query.substring(0, 147)}...` : item.query }}</p>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-if="showJsGroovyEditor" v-model="showJsGroovyEditor" max-width="71%">
      <global-query-builder-editor
        :item="selectedItem"
        :with-buttons="true"
        :can-edit="canEdit"
        :vars="vars"
        :step-type="stepType"
        :code-type="step.properties.scriptEngine"
        :single-step="singleStep"
        @closeDialog="closeEdit()"
        @fromGlobalEditor="updateFromEditor($event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import globalQueryBuilderEditor from '@/components/ui/GlobalQueryBuilderEditor.vue'

export default {
  components: {
    globalQueryBuilderEditor
  },
  props: {
    language: {
      type: String,
      default: () => {
        return 'en'
      }
    },
    vars: {
      type: Array,
      default: () => {
        return []
      }
    },
    step: {
      type: Object,
      default: () => {
        return {}
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    full: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    singleStep: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data: () => ({
    showJsGroovyEditor: false,
    deconstructedData: [],
    showGlobalEditor: false,
    selectedItemIndex: null,
    selectedRowType: '',
    selectedItem: null,
    initDone: false
  }),
  computed: {
    ...mapState('app', ['userSettings'])
  },
  watch: {
    deconstructedData: {
      handler() {
        this.$emit('dataChanged', this.deconstructedData)
      },
      deep: true
    }
  },
  created() {
    this.deconstructedData = this.data ? structuredClone(this.data) : []
  },
  methods: {
    updateFromEditor(data) {
      this.deconstructedData[this.selectedItemIndex] = data
      this.showJsGroovyEditor = false
      this.selectedItemIndex = null
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    },
    editRow(i) {
      this.selectedItem = this.deconstructedData[i]
      this.selectedItemIndex = i
      this.showJsGroovyEditor = true
    },
    closeEdit() {
      if (this.selectedItem?.query === '' && this.selectedItem?.condition === '') {
        this.deconstructedData.splice(this.selectedItemIndex, 1)
      }
      this.selectedItem = null
      this.selectedItemIndex = null
      this.showJsGroovyEditor = false
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
